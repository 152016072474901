<template>
  <div>
    <!--loading-->
    <vs-progress color="primary" :height="1" v-if="isLoadingInitData"/>

    <div class="vx-row mb-3">
      <div class="vx-col lg:w-9/12 w-full">
        <vx-card>
          <vs-tabs alignment="fixed" v-model="activeTab">
            <vs-tab label="Detail Pengajuan Media" icon-pack="feather">
              <TabDetail :isActive="activeTab === 0" :initData="initData.data"/>
            </vs-tab>
          </vs-tabs>
        </vx-card>
      </div>

      <div class="vx-col lg:w-3/12 w-full mt-3 lg:mt-0">
        <!--approval progress-->
        <vx-card>
          <div class="flex items-center mb-base">
            <feather-icon icon="LayersIcon" svgClasses="h-5 w-5" class="mr-2"/>
            <h5 class="font-medium">Approval Progress</h5>
          </div>
          <ApprovalProgress
            ref="approvalProgress"
            type="PENGAJUAN-MEDIA"
            :id-ref="$route.params.idPengajuanMedia"
            :isShowSigningAction="true"
            @signingClicked="onClickSigningAction"/>
        </vx-card>
      </div>
    </div>

    <!--modals-->
    <PengajuanMediaApprovalSigning
      :isActive.sync="modalSigning.active"
      :idPengajuanMedia="modalSigning.idPengajuanMedia"
      :status="modalSigning.status"
      @success="onSigned"/>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import modulePengajuanMediaApproval from '@/store/modules/approvals/pengajuan-media-approval/pengajuan-media-approval.store'
import PengajuanMediaRepository from '@/repositories/marketing/pengajuan-media-repository'

export default {
  name: 'PengajuanMediaApprovalShow',
  components: {
    TabDetail: () => import('@/views/pages/approvals/pengajuan-media-approval/parts/TabDetail'),
    PengajuanMediaApprovalSigning: () => import('@/views/pages/approvals/pengajuan-media-approval/PengajuanMediaApprovalSigning'),
    ApprovalProgress: () => import('@/views/components/approval-progress/ApprovalProgress')
  },
  mounted () {
    this.getInitData()
  },
  data () {
    return {
      isLoadingInitData: false,
      initData: {
        data: {
          media: {}
        }
      },
      modalSigning: {
        active: false,
        idPengajuanMedia: this.$route.params.idPengajuanMedia,
        status: null
      }
    }
  },
  computed: {
    activeTab: {
      get () {
        return this.$store.state.approvals.pengajuanMediaApproval.activeTab
      },
      set (value) {
        this.$store.commit('approvals/pengajuanMediaApproval/SET_ACTIVE_TAB', value)
      }
    },
    ...mapState({
      dataTabDetail: state => state.approvals.pengajuanMediaApproval.tabDetail
    })
  },
  methods: {
    onSigned () {
      this.getInitData()
      this.$refs.approvalProgress.refresh()
    },

    getInitData () {
      this.isLoadingInitData = true

      const idPengajuanMedia = this.$route.params.idPengajuanMedia
      PengajuanMediaRepository.show(idPengajuanMedia)
        .then(response => {
          this.initData.data = this.buildInitData(response.data.data)
        })
        .catch(error => {
          if (error.response.status === 404) {
            this.$router.push({ name: '404' })
          } else {
            console.log(error)
            this.notifyError('Terjadi kesalahan.')
          }
        })
        .finally(() => {
          this.isLoadingInitData = false
        })
    },

    buildInitData (data) {
      return {
        id: data.header.id,
        no_pengajuan: data.header.no_pengajuan,
        tgl_pengajuan: data.header.tgl_pengajuan,
        tgl_mulai_publish: data.header.tgl_mulai_publish,
        tipe_platform: data.header.tipe_platform,
        id_proyek: data.header.id_proyek,
        nama_proyek: data.header.nama_proyek,
        keterangan: data.header.keterangan,
        tgl_selesai_publish: data.header.tgl_selesai_publish,
        lokasi: data.header.lokasi_list,
        biaya: data.header.biaya,
        sisa_saldo: data.header.sisa_saldo,
        status_approval: data.header.status_approval,
        current_step: data.header.current_step,
        final_step: data.header.final_step,
        media: data.header.media,
        recipients: data.recipients
      }
    },

    onClickSigningAction (status) {
      this.modalSigning.status = status
      this.modalSigning.active = true
    }
  },
  beforeCreate () {
    this.$store.registerModule(['approvals', 'pengajuanMediaApproval'], modulePengajuanMediaApproval)
  },
  beforeDestroy () {
    this.$store.commit('approvals/pengajuanMediaApproval/RESET_STATE')
    this.$store.unregisterModule(['approvals', 'pengajuanMediaApproval'])
  }
}
</script>
